import { getCompanys } from "../../../../api/backOffice";
import companysCreate from "../company/companysCreate.vue";
import CompanysSetting from "../company/companysSetting.vue";

export default {
  components: {
    companysCreate,
    CompanysSetting,
  },
  data() {
    return {
      tabs: 0,
      tabsPags: 0,
    };
  },

  methods: {
    // 등록된 업체의 관리자 계정을 조회 하는 API 호출
    async callGetCompanys() {
      try {
        const result = await getCompanys();

        if (result.status !== 200) throw `error : ${result.status}`;

        this.companys = result.data;
      } catch (e) {
        console.log(e);
      }
    },

    // 전달 받은 값에 따라 화면에 출력할 화면 선택
    // 0 : 업체 목록
    // 1 : 미정
    tobMove(e) {
      this.tabsPags = e;
    },
  },

  created() {},
};
