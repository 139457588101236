import { instance } from "./index";

// 등록된 업체를 검색 하여 반환 하는 API 호출
function getCompanys() {
  return instance.get(`backOffice/companys/allCompanys`);
}

// uuid(email) 중복 검색
function findOwnerUuid(id) {
  return instance.get(`backOffice/companys/findUuid/${id}`);
}

// setting 업체를 검색하여 반환 하는 API 호출
function getSettingCompnays() {
  return instance.get("backOffice/companys/settingCompnays");
}

// 신규 회사 생성
function createCompany(form) {
  return instance.post("backOffice/companys/createCompnay", form);
}

// 오더 유저 생성

function createOwnerUser(form) {
  return instance.post("backOffice/companys/createOwnerUser", form);
}

// // 업체의 isActive 를 변경하는 API 호출
// function isActiveChage(param) {
//   return instance.post(`backOffice/companys/activeChagnge`, param);
// }

// 업체의 trial 기간 시작 API 호출
function isTrialChage(param) {
  return instance.post(`backOffice/companys/trialStart`, param);
}

// 업체의 trial 기간 연장 API 호출
function trialProlongation(param) {
  return instance.post(`backOffice/companys/trialProlongation`, param);
}

// 통계 데이터 호출 목록 시작
/**
 * 회사 리스트 호출
 */
function getTotalCompnays() {
  return instance.get(`backOffice/total/companys`);
}

// 통계 데이터 호출 목록 끝
function postTotalTopCompnays(type) {
  return instance.post(`backOffice/total/topCompanys`, { type });
}

export {
  getCompanys,
  findOwnerUuid,
  // isActiveChage,
  getSettingCompnays,
  isTrialChage,
  trialProlongation,
  createCompany,
  createOwnerUser,
  getTotalCompnays,
  postTotalTopCompnays,
};
