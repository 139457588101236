import { instance } from "./index";

function getFilters(url, parmas) {
  return instance.post(`${url}/getFilters`, parmas);
}

function search(url, parmas) {
  return instance.post(`${url}`, parmas);
}

export { getFilters, search };
