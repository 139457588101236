<template>
  <div class="adminTotalContainer">
    <!-- top -->
    <ListContainer />
    <GraphnContainer />
    <!-- bottom -->
  </div>
</template>

<script>
import ListContainer from "@/components/pages/backOffice/total/BottomContainer.vue";
import GraphnContainer from "@/components/pages/backOffice/total/TopContainer.vue";

export default {
  components: {
    ListContainer,
    GraphnContainer,
  },
  data() {
    return {};
  },

  methods: {},

  async created() {},
};
</script>
