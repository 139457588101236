export default [
  {
    text: "회사명",
    align: "center",
    class: "tableHeaderBody",
    value: "name",
    filterable: false,
    checked: false,

    header: "회사명",
    key: "name",
    width: "20",
  },
  {
    text: "최종활동 날짜",
    align: "center",
    class: "tableHeaderBody",
    value: "lastActiveDate",
    checked: false,
    type: "date",

    header: "최종활동 날짜",
    key: "lastActiveDate",
    width: "13",
  },
  {
    text: "인원 수",
    value: "userCount",
    align: "center",
    class: "tableHeaderBody",
    type: "num",
    checked: false,

    header: "인원 수",
    key: "userCount",
    width: "10",
  },
  {
    text: "오더 갯수",
    align: "center",
    class: "tableHeaderBody",
    value: "orderCount",
    type: "num",
    checked: false,

    header: "오더 갯수",
    key: "orderCount",
    width: "10",
  },
  {
    text: "대표자명",
    align: "center",
    class: "tableHeaderBody",
    value: "officer",
    type: "owner",
    checked: false,

    header: "대표자명",
    key: "officer",
    width: "10",
  },
  {
    text: "주소",
    align: "center",
    class: "tableHeaderBody",
    value: "address",
    checked: false,

    header: "주소",
    key: "address",
    width: "40",
  },
  {
    text: "연락처",
    align: "center",
    class: "tableHeaderBody",
    value: "tel",
    checked: false,

    header: "연락처",
    key: "tel",
    width: "20",
  },
  {
    text: "생성일자",
    align: "center",
    class: "tableHeaderBody",
    value: "createDate",
    checked: false,
    type: "date",

    header: "생성일자",
    key: "createDate",
    width: "13",
  },
];
