<template>
  <div class="totalBodyContainer" style="padding: 10px">
    <div class="excelBtnDiv">
      <div class="excelBtn" @click="excelDownlod">엑셀 다운로드</div>
    </div>
    <TableHeader
      :headers="companysHeaders"
      @changeHeaderChecked="changeHeaderChecked"
    />

    <overlay-scrollbars
      :options="{
        overflowBehavior: {
          x: 'hidden',
        },
      }"
      ref="scrollBar"
      defer
    >
      <div style="max-height: 400px; height: 400px">
        <template v-if="companys.length">
          <div
            class="totalCompanysGrid"
            v-for="company in companys"
            :key="company._id"
          >
            <div
              v-for="head in companysHeaders"
              :key="head.text"
              class="bodyItem"
            >
              <span v-if="head.type === 'num'">
                {{ company[head.value] | makeComma }}
              </span>
              <span v-else-if="head.type === 'owner'">
                {{
                  company[head.value] ? company[head.value] : company.ownerUser
                }}
              </span>
              <span v-else> {{ company[head.value] }}</span>
            </div>
          </div>
        </template>
      </div>
    </overlay-scrollbars>
  </div>
</template>

<script>
import FilterActionName from "@/components/pages/common/FilterActionName.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import { getTotalCompnays } from "@/api/backOffice";
import companysHeaders from "./totalCompanyHeader.js";
import TableHeader from "@/components/atoms/FiltersElements/TableHeader.vue";
import exceljs from "exceljs";

export default {
  components: {
    TableHeader,
  },
  computed: {
    ...mapState({
      companys: "adminTotalCompanys",
    }),
  },

  data() {
    return {
      companysHeaders,
      actionName: FilterActionName[this.$route.name],
    };
  },

  methods: {
    ...mapMutations({
      // 검색 param 수정
      setCompanys: "setFilterSearchData",
    }),

    changeHeaderChecked(index, isChecked) {
      this.companysHeaders[index].checked = isChecked;
    },

    excelDownlod() {
      try {
        const workbook = new exceljs.Workbook();
        workbook.created = new Date(); //생성일
        workbook.modified = new Date(); //수정일

        workbook.addWorksheet(this.$store.state.Today);
        const sheetOne = workbook.getWorksheet(1);
        sheetOne.columns = this.companysHeaders;
        sheetOne.getRow(1).alignment = {
          vertical: "center",
          horizontal: "center",
        };
        sheetOne.getRow(1).style = {
          font: { bold: true, name: "Arial Black", size: 30 },
        }; // 헤더 스타일지정

        this.companys.forEach((x) => {
          sheetOne.addRow(x);
        });

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${this.$store.state.Today}_업체현황.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      } catch (e) {
        console.error(e);
      }
    },

    async callTotalCompnays() {
      try {
        const result = await getTotalCompnays();

        if (result.status !== 200) throw `error : ${result.status}`;

        this.setCompanys({
          dataName: this.actionName.dataName,
          data: result.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },

  async created() {
    await this.callTotalCompnays();
  },
};
</script>
