<template>
  <div class="totalBodyContainer totalTopContainer">
    <div class="leftContainer">
      <div class="checkBoxArea">
        <div class="checkBoxDiv">
          <input
            value="date"
            type="radio"
            name="listType"
            checked
            @click="topTyeBtnClick('date')"
          />
          미사용 일자 순
        </div>
        <div class="checkBoxDiv">
          <input
            value="order"
            type="radio"
            name="listType"
            @click="topTyeBtnClick('order')"
          />
          오더 건 순
        </div>
      </div>

      <div class="titleArea">
        <div class="leftTitle">회사 명</div>
        <div class="rightSubTitle">{{ topListSubTitle }}</div>
      </div>

      <div v-for="(company, i) in companys" :key="company.id" class="listArea">
        <div class="leftNum">{{ i + 1 }}.</div>
        <div class="centerName">
          {{ company.name }}
        </div>

        <div class="rightValue">
          <span v-if="topListType === 'date'">
            {{ company.lastActiveDate ? company.lastActiveDate : "미사용" }}
          </span>
          <span v-if="topListType === 'order'">
            {{ company.orderCount | makeComma }} 건
          </span>
        </div>
      </div>
    </div>
    <div class="rightContaier">
      <div class="GraphTitle">{{ rightContaierTitle }}</div>
      <apexcharts
        type="bar"
        height="300px"
        width="90%"
        :options="barOption"
        :series="series"
        style="margin-top: 10px"
      />
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { postTotalTopCompnays } from "@/api/backOffice";

export default {
  components: {
    apexcharts: VueApexCharts,
  },

  data() {
    return {
      companys: [],
      topListType: "date",
    };
  },
  computed: {
    isTypeDate() {
      return this.topListType === "date";
    },
    topListMainTitle() {
      return this.isTypeDate ? "미사용 날짜 순" : "오더 등록건 순";
    },
    topListSubTitle() {
      return this.isTypeDate ? "최종활동 날짜" : "오더 건";
    },

    rightContaierTitle() {
      let title = "";
      if (this.topListType === "date") title = "원단마니아 미사용 날짜(일)";
      if (this.topListType === "order") title = "오더 등록수";
      return title;
    },

    series() {
      return [
        {
          name: `${this.isTypeDate ? "미사용 일" : "오더 건"}`,
          data: this.companys.map((x) =>
            this.isTypeDate ? x.unusedDate : x.orderCount,
          ),
        },
      ];
    },

    barOption() {
      return {
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
            barHeight: "30px",
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function (val, opt) {
            return `${val}`;
          },
          style: {
            colors: ["#666"],
            // fontWeight: "bold",
          },
          offsety: 0,
        },
        xaxis: {
          title: {
            text: `${this.isTypeDate ? "미사용 날짜(일)" : "오더 (건)"}`,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },

          categories: this.companys.map((x) => x.name),
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 100,
            maxWidth: 100,
          },
        },
      };
    },
  },
  methods: {
    // 전달 받은 data 를 set 해주는 함수
    setCompanys(data) {
      this.companys = data ? data : [];
    },

    topTyeBtnClick(type) {
      this.topListType = type;
      this.callTopCompnays();
    },

    async callTopCompnays() {
      try {
        const result = await postTotalTopCompnays(this.topListType);

        if (result.status !== 200) throw `error : ${result.status}`;

        this.setCompanys(result.data);
      } catch (e) {
        console.log(e);
      }
    },
  },

  async created() {
    await this.callTopCompnays();
  },
};
</script>
