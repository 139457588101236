<template>
  <div class="totalCompanysGrid">
    <div
      v-for="head in headers"
      :key="head.text"
      class="totalCompanysGridHeader"
    >
      <div class="filterHeadTitle" @click="filterSet(head)">
        {{ head.text }}
      </div>
      <filterNormal
        v-if="!head.checked"
        class="fiterBeacon"
        @click="filterSet(head)"
      />
      <filterActive
        v-if="head.checked"
        class="fiterBeacon"
        @click="filterSet(head)"
      />
      <!-- filter menu -->
      <FilterMenu
        v-if="isFilterMenu && head.value === filterManuValue"
        :head="head.value"
        :type="head.type"
        @close="closeFilterMenu"
        @appleyHeader="appleyHeader"
        @cleaerHeader="cleaerHeader"
      />
    </div>
  </div>
</template>
<script>
import filterNormal from "@/assets/image/svg/filter.svg";
import filterActive from "@/assets/image/svg/filter_active.svg";
import FilterMenu from "@/components/atoms/FiltersElements/FilterMenu.vue";

export default {
  props: {
    headers: Array,
  },

  components: {
    filterNormal,
    filterActive,
    FilterMenu,
  },

  data() {
    return {
      isFilterMenu: false,
      filterManuValue: "",
    };
  },

  methods: {
    filterCheckClick(i) {
      const elements = this.copyData(this.checkElements);
      elements[i].checked = !elements[i].checked;
      this.checkElements = elements;
    },

    filterSet(head) {
      if (this.isFilterMenu) {
        this.isFilterMenu = !(this.filterManuValue === head.value);
        this.filterManuValue =
          this.filterManuValue === head.value ? "" : head.value;
      } else {
        this.isFilterMenu = !this.isFilterMenu;
        this.filterManuValue = head.value;
      }
    },

    // 해더 checked ture
    appleyHeader(head) {
      const index = this.headers.findIndex((x) => x.value === head);
      this.$emit("changeHeaderChecked", index, true);
    },

    // 해더 checked false
    cleaerHeader(head) {
      const index = this.headers.findIndex((x) => x.value === head);
      this.$emit("changeHeaderChecked", index, false);
    },

    closeFilterMenu() {
      this.isFilterMenu = false;
      this.filterManuValue = "";
    },
  },
};
</script>
