<template>
  <div>
    <v-dialog v-model="isDialog" width="500px" persistent>
      <div class="companyCreateForm">
        <div class="settingApplyTitle">신규 업체 생성</div>

        <div class="settingApplyDiv">
          <div class="settingApplyLabel">업체명</div>
          <input v-model="company" class="settingApplyInput" />

          <div class="settingApplyLabel">사업자번호</div>
          <input
            v-model="businessNum"
            class="settingApplyInput"
            maxlength="10"
            @input="businessNumInput($event)"
          />

          <div class="settingApplyLabel">대표</div>
          <input v-model="name" class="settingApplyInput" />

          <div class="settingApplyLabel">전화번호</div>
          <input
            v-model="tel"
            class="settingApplyInput"
            maxlength="13"
            @input="telInput($event)"
          />

          <div class="settingApplyLabel">주소</div>
          <input v-model="address" class="settingApplyInput" />

          <div class="settingApplyLabel">fax</div>
          <input v-model="fax" class="settingApplyInput" />
        </div>

        <div class="createCompnayBottom">
          <div class="companyCreateDialogBtn closeBtn" @click="dialogClose()">
            닫기
          </div>
          <div class="companyCreateDialogBtn createBtn" @click="saveCompany">
            저장
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { createCompany } from "../../../../api/backOffice";

export default {
  props: {
    isDialog: Boolean,
  },
  data() {
    return {
      company: "",
      businessNum: "",
      name: "",
      tel: "",
      address: "",
      fax: "",
      saveForm: {},
    };
  },
  methods: {
    snackbarOn(txt) {
      this.$emit("snackbarOn", txt);
    },

    initForm() {
      this.company = "";
      this.businessNum = "";
      this.name = "";
      this.tel = "";
      this.address = "";
      this.fax = "";
      this.saveForm = {};
    },

    dialogClose() {
      this.initForm();
      this.$emit("close");
    },

    //전화번호 입력
    telInput(e) {
      const hypenTel = this.getPhoneMask(e.target.value);

      this.tel = hypenTel;
    },

    businessNumInput(e) {
      const regex = /[^0-9]/g;
      this.businessNum = e.target.value.replace(regex, "");
    },

    async callCrateCompnay() {
      try {
        const result = await createCompany(this.saveForm);

        if (result.status !== 200) throw `error : ${result.status}`;

        await this.$emit("callGetCompanys");

        this.snackbarOn("저장에 성공 했습니다");
        this.dialogClose();
      } catch (e) {
        this.snackbarOn("저장에 실패 했습니다");
      }
    },

    saveCompany() {
      const telRegEx =
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

      if (!this.name) {
        this.snackbarOn("대표 입력해 주세요");
        return;
      }

      if (!this.businessNum || this.businessNum.length < 10) {
        this.snackbarOn("신청자명을 입력해 주세요");
        return;
      }
      if (!this.tel) {
        this.snackbarOn("전화번호를 입력해 주세요");
        return;
      }

      if (!telRegEx.test(this.tel)) {
        this.snackbarOn("올바른 양식의 전화번호를 입력해주세요");
        return;
      }

      if (!this.address) {
        this.snackbarOn("주소를 입려해 주세요");
        return;
      }

      if (confirm("기초데이터 설정 신청을 하시겠습니까?")) {
        this.saveForm = {
          company: this.company,
          name: this.name,
          businessNum: this.businessNum,
          tel: this.tel.replace(/-/g, ""),
          address: this.address,
          fax: this.fax ? this.fax.replace(/-/g, "") : "",
        };

        this.callCrateCompnay();
      }
    },
  },
};
</script>
<style></style>
