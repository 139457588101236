<template>
  <overlay-scrollbars
    :options="{
      overflowBehavior: {
        x: 'hidden',
      },
    }"
    ref="scrollBar"
    defer
  >
    <div class="filterCheckDiv">
      <div
        v-for="(element, i) in elements"
        :key="element.value"
        class="filterAllCheckDiv"
      >
        <input v-model="element.checked" type="checkbox" class="checkBox" />
        <div class="checkBoxTxt" @click="$emit('checkClick', i)">
          {{ element.value }}
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>
<script>
export default {
  props: {
    elements: Array,
  },
};
</script>
