<template>
  <div class="filterCheckMenu">
    <div class="filterClearDiv">
      <div class="filterClearBtn" @click="fitlerClear">필터 해제</div>
    </div>

    <!-- 날짜 시작일 선택 -->
    <div v-if="this.type === 'date'" class="filterDateDiv">
      <div style="width: 30%">기간 선택:</div>
      <v-menu
        ref="isStartDateCal"
        :value="isStartDateCal"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
            flat
            solo
            class="ml-0 mr-0"
            @change="setDate"
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          no-title
          scrollable
          locale="kr"
          @input="$refs.isStartDateCal.save(startDate)"
          @change="setDate()"
        >
        </v-date-picker>
      </v-menu>
      <div>~</div>
      <v-menu
        ref="isEndDateCal"
        :value="isEndDateCal"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
            flat
            solo
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          no-title
          scrollable
          locale="kr"
          @input="$refs.isEndDateCal.save(endDate)"
          @change="setDate()"
        >
        </v-date-picker>
      </v-menu>
    </div>

    <!-- 필터 영역 -->
    <!-- 검색 -->
    <div v-if="this.type !== 'date'" class="fitlerInputDiv">
      <input
        :value="inputData"
        class="filterInput"
        placeholder="검색어를 입력해 주세요."
        @input="searchKeywordInput($event)"
        @keypress.enter="applyBtnClick()"
      />
    </div>

    <!-- 전체 선택 -->
    <div class="filterAllCheckDiv">
      <template v-if="!inputData">
        <input
          v-model="allChecked"
          type="checkbox"
          class="allCheckBox"
          @click="filterAllCheck"
        />
        <div class="allCheckBoxTxt" @click="filterAllCheck">전체 선택</div>
      </template>
      <template v-else>
        <input
          v-model="inputAllChecked"
          type="checkbox"
          class="allCheckBox"
          @click="filterAllCheck"
        />
        <div class="allCheckBoxTxt" @click="filterAllCheck">
          검색 결과 전체 선택
        </div>
      </template>
    </div>
    <!-- 검색 리스트 -->
    <FilterElements
      :elements="this.inputData ? this.inputFilter : this.checkElements"
      @checkClick="filterCheckClick"
    />

    <!-- 적용, 닫기 버튼 -->
    <div class="filterBtnDiv">
      <div class="applyBtnDiv">
        <div class="applyBtn" @click="applyBtnClick()">적 용</div>
      </div>
      <div class="closeBtnDiv">
        <div class="closeBtn" @click="$emit('close')">닫 기</div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterElements from "@/components/atoms/FiltersElements/Elements.vue";
import FilterActionName from "@/components/pages/common/FilterActionName.js";
import { mapMutations } from "vuex";
import { getFilters, search } from "@/api/filter.js";

export default {
  props: {
    head: String,
    type: String,
  },
  computed: {
    params() {
      return this.$store.state[this.actionName.stateName];
    },

    // filter 요소값 호출 시에 사용 하는 param 생성
    fitlerParams() {
      const findParams = JSON.stringify(this.makeParams());
      const fieldParams = JSON.stringify(
        -1 < this.paramIndex ? this.params[this.paramIndex].findParams : [],
      );
      return {
        field: this.head,
        findParams,
        fieldParams,
      };
    },

    checkedParams() {
      const checkElements = this.inputData
        ? this.inputFilter
        : this.checkElements;

      return checkElements.length
        ? checkElements.filter((x) => x.checked).map((x) => x.value)
        : [];
    },
  },
  components: {
    FilterElements,
  },
  data() {
    return {
      allChecked: true,
      inputAllChecked: true,
      checkElements: [],
      actionName: FilterActionName[this.$route.name],
      paramIndex: -1,
      inputData: "",
      inputFilter: [],
      yearFilter: [],
      monthFilter: [],
      dayFilter: [],

      isStartDateCal: false,
      isEndDateCal: false,
      startDate: "",
      endDate: "",
    };
  },

  methods: {
    ...mapMutations({
      setParams: "setFilterFieldParams",
      setCompanys: "setFilterSearchData",
    }),

    // 필터 전체 검색 true/false 를 바꿔주는 함수
    setAllFilterChecked() {
      if (!this.inputData)
        this.allChecked = !this.checkElements.find((x) => !x.checked);
      else this.inputAllChecked = !this.inputFilter.find((x) => !x.checked);
    },

    searchKeywordInput(event) {
      this.inputData = event.target.value;
      this.inputFilter = this.copyData(
        this.checkElements.filter((x) =>
          String(x.value).toLowerCase().includes(this.inputData.toLowerCase()),
        ),
      ).map((x) => {
        x.checked = true;
        return x;
      });
    },

    // 필터 요속값을 검색할 조건을 생성하여 반환 하는 함수
    makeParams() {
      let params = [];
      if (this.params.length) {
        if (-1 < this.paramIndex) {
          for (let i = 0; i < this.paramIndex; i++) {
            params.push(this.params[i]);
          }
        } else params = this.params;
      }
      return params;
    },

    // filter 요소값을 검색하는 함수
    async callGetFiltersApi() {
      try {
        const result = await getFilters(
          this.actionName.routeName,
          this.fitlerParams,
        );
        if (result.status !== 200) throw "데이터 호출 실패";

        this.checkElements = result.data.filters;
        this.setAllFilterChecked();
      } catch (e) {
        console.log(e);
      }
    },

    // 선택된 param 내용으로 검색
    async searchData() {
      try {
        const result = await search(this.actionName.searchName, {
          findParams: JSON.stringify(this.params),
        });

        if (result.status !== 200) throw "데이터 호출 실패";

        this.setCompanys({
          dataName: this.actionName.dataName,
          data: result.data.companys,
        });
      } catch (e) {
        console.log(e);
      }
    },

    // 필터 전체 선택
    filterAllCheck() {
      if (!this.inputData) {
        this.allChecked = !this.allChecked;
        this.checkElements = this.checkElements.map((x) => {
          x.checked = this.allChecked;
          return x;
        });
      } else {
        this.inputAllChecked = !this.inputAllChecked;
        this.inputFilter = this.inputFilter.map((x) => {
          x.checked = this.inputAllChecked;
          return x;
        });
      }
    },

    // 필터 체크박스 클릭
    filterCheckClick(i) {
      const elements = this.copyData(
        this.inputData ? this.inputFilter : this.checkElements,
      );
      elements[i].checked = !elements[i].checked;
      if (this.inputData) this.inputFilter = elements;
      else this.checkElements = elements;
      this.setAllFilterChecked();
    },

    //필터해제
    async fitlerClear() {
      if (this.paramIndex === -1) return;
      const params = this.copyData(this.params);
      params.splice(this.paramIndex, 1);
      this.setParams({
        stateName: this.actionName.stateName,
        data: params,
      });

      await this.searchData();
      this.$emit("close");
      this.$emit("cleaerHeader", this.head);
    },

    // 날짜 검색 버튼 클릭
    crateDateFilter() {
      const dateList = this.checkElements.filter((x) => x.value);
      const dateListSize = dateList.length - 1;
      this.startDate = dateList[dateListSize].value;
      this.endDate = dateList[0].value;
    },

    // 날짜 필터
    setDate() {
      this.inputData = this.startDate;
      this.inputFilter = this.copyData(
        this.checkElements.filter(
          (x) => this.startDate <= x.value && x.value <= this.endDate,
        ),
      );
    },

    // 적용 버튼 클릭
    async applyBtnClick() {
      const params = this.copyData(this.params);
      const param = {
        field: this.head,
        findParams: this.checkedParams,
      };

      if (-1 < this.paramIndex) params[this.paramIndex] = param;
      else params.push(param);

      this.setParams({
        stateName: this.actionName.stateName,
        data: params,
      });

      await this.searchData();
      this.$emit("close");
      this.$emit("appleyHeader", this.head);
    },
  },

  async created() {
    this.paramIndex = this.params.findIndex((x) => x.field === this.head);
    await this.callGetFiltersApi();
    if (this.type === "date") await this.crateDateFilter();
  },
};
</script>
