<template>
  <div>
    <div class="d-flex submenuArea compnayLists">
      <div class="d-flex align-center infoArea">
        총 &nbsp;<span class="blue--text"> {{ companys.length }} </span>개 업체
      </div>
      <div class="trialPlusBtn">
        <div class="settingBtn" @click="allCheckBoxClick(true)">전체 선택</div>
        <div class="settingBtn" @click="allCheckBoxClick(false)">전체 해제</div>
        <div class="settingBtn" @click="callTrialProlongation()">기간 연장</div>
      </div>
    </div>
    <div class="compnaySettingGrid">
      <div
        v-for="head in compnaysHeader"
        :key="head.text"
        class="compnaysGridHeader"
      >
        <div>
          <span>{{ head.text }}</span>
        </div>
      </div>
    </div>
    <overlay-scrollbars
      :options="{
        overflowBehavior: {
          x: 'hidden',
        },
      }"
      ref="scrollBar"
      defer
    >
      <div
        style="
          max-height: calc(100vh - 224px);
          border-bottom: thin solid #0000001f;
        "
      >
        <div
          class="compnaySettingGrid compnaysGridGridBody"
          v-for="(item, i) in companys"
          :key="item._id"
        >
          <div
            v-for="head in compnaysHeader"
            :key="head.value"
            class="bodyItem"
          >
            <div v-if="head.type === 'checkBox' && item.is_trial">
              <input
                v-model="item.isCheckd"
                type="checkbox"
                @click="checkBoxClick(i)"
              />
            </div>
            <span v-if="head.type === 'text'">{{
              item[head.value] ? item[head.value] : ""
            }}</span>
            <div
              v-if="head.type === 'setting'"
              class="settingBtn"
              @click="settingBtnClick(item.id, head.value)"
            >
              {{ head.value }}
            </div>

            <div
              v-if="head.type === 'trial'"
              class="trialBtn"
              :class="item[head.value] ? 'trialTrue' : 'trialFalse'"
              @click="trialStart(item.id, item.name, item.is_trial)"
            >
              사용 시작
            </div>
          </div>
        </div>
      </div>
    </overlay-scrollbars>
  </div>
</template>
<script>
import {
  getSettingCompnays,
  isTrialChage,
  trialProlongation,
} from "../../../../api/backOffice";

export default {
  data() {
    return {
      companys: [],
      compnaysHeader: [
        {
          text: "",
          type: "checkBox",
        },
        {
          text: "업체명",
          value: "name",
          type: "text",
        },
        // {
        //   text: "id",
        //   value: "id",
        //   type: "text",
        // },
        {
          text: "거래처 정보",
          value: "clients",
          type: "setting",
        },
        {
          text: "아이템 정보",
          value: "items",
          type: "setting",
        },
        {
          text: "복종 정보",
          value: "clothestypes",
          type: "setting",
        },
        {
          text: "시작 일",
          value: "trial_start_date",
          type: "text",
        },
        {
          text: "종료 일",
          value: "trial_end_date",
          type: "text",
        },
        {
          text: "무료사용 시작",
          value: "is_trial",
          type: "trial",
        },
      ],
    };
  },
  methods: {
    // setting 버튼 클릭시 이벤트
    settingBtnClick(id, type) {
      console.log(id);
      console.log(type);
    },

    // 무료 사용 시작
    async trialStart(id, name, trial) {
      if (trial) return;
      if (confirm(`${name} 무료 사용 시작하시겠습니까?`)) {
        try {
          const result = await isTrialChage({ id });

          if (result.status !== 200) throw `error : ${result.status}`;

          await this.callSettingCompanys();
        } catch (e) {
          console.log(e);
        }
      }
    },

    // all checkBox on Click
    allCheckBoxClick(isCheckd) {
      const chageCompnays = this.companys.map((company) => {
        if (company.is_trial) company.isCheckd = isCheckd;
        return company;
      });

      this.companys = chageCompnays;
    },

    // 기간 연장 함수
    async callTrialProlongation() {
      const id = this.companys
        .filter((company) => company.isCheckd && company.is_trial)
        .map((company) => company.id);

      if (id.length < 1) {
        alert("무료 기간 연장할 회사를 선택해 주세요");
        return;
      }

      if (confirm(`${id.length} 업체 기간 연장 하시겠습니까?`)) {
        try {
          const result = await trialProlongation({ id });

          if (result.status !== 200) throw `error : ${result.status}`;

          await this.callSettingCompanys();
        } catch (e) {
          console.log(e);
        }
      }
    },

    // checkbox Click
    checkBoxClick(i) {
      const chageCompnays = this.copyData(this.companys);

      chageCompnays[i].isCheckd = !chageCompnays[i].isCheckd;

      this.companys = chageCompnays;
    },

    async callSettingCompanys() {
      try {
        const result = await getSettingCompnays();

        if (result.status !== 200) throw `error : ${result.status}`;

        this.companys = result.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.callSettingCompanys();
  },
};
</script>
