<template>
  <div>
    <v-dialog v-model="isDialog" width="500px" persistent>
      <div class="userInputForm">
        <div class="userInputFormTitle">
          {{ `${company.name ? company.name : ""} 관리자 계정 생성` }}
        </div>

        <div class="userInputDiv">
          <div class="userInputTitle">이메일:</div>
          <div class="userInput">
            <div class="settingEmailDiv">
              <input v-model="uuid" class="emailInput" />
              <span>@</span>
              <input
                v-model="domain"
                class="domainInput"
                :disabled="selectDoamin !== '직접입력'"
              />
              <select
                :value="selectDoamin"
                class="domainSelect"
                @change="domainChage($event)"
              >
                <option v-for="item in doamins" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="userInputDiv">
          <div class="userInputTitle">비밀번호</div>
          <div class="userInput">
            <div class="settingPwDiv">
              <input v-model="password" class="dataPwInput" />
              <div style="width: 10px"></div>
              <div class="randomPwBtn" @click="makeupPw()">난수 생성</div>
            </div>
          </div>
        </div>
        <div class="userInputDiv">
          <div class="userInputTitle">이름</div>
          <div class="userInput">
            <input v-model="name" class="dataInput" />
          </div>
        </div>
        <div class="userInputDiv">
          <div class="userInputTitle">전화번호</div>
          <div class="userInput">
            <input
              :value="tel"
              maxlength="13"
              @input="telInput($event)"
              class="dataInput"
            />
          </div>
        </div>

        <div class="createCompnayBottom">
          <div class="companyCreateDialogBtn closeBtn" @click="dialogClose()">
            닫 기
          </div>
          <div class="companyCreateDialogBtn createBtn" @click="saveOwner()">
            저 장
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { findOwnerUuid, createOwnerUser } from "../../../../api/backOffice";

export default {
  props: {
    close: Function,
    isDialog: Boolean,
    company: {
      type: Object,
    },
  },
  data() {
    return {
      uuid: "",
      domain: "",
      selectDoamin: "",
      password: "",
      name: "",
      tel: "",

      saveForm: {},

      doamins: [
        "직접입력",
        "naver.com",
        "gmail.com",
        "hanmail.com",
        "nate.com",
        "kakao.com",
      ],
    };
  },

  methods: {
    //domain 변경
    domainChage(e) {
      this.selectDoamin = e.target.value;
      this.domain = this.selectDoamin !== "직접입력" ? this.selectDoamin : "";
    },

    snackbarOn(txt) {
      this.$emit("snackbarOn", txt);
    },

    //전화번호 입력
    telInput(e) {
      const hypenTel = this.getPhoneMask(e.target.value);

      this.tel = hypenTel;
    },

    dialogClose() {
      this.initForm();
      this.$emit("close");
    },

    initForm() {
      this.uuid = "";
      this.domain = "";
      this.selectDoamin = "직접입력";
      this.password = "";
      this.name = "";
      this.tel = "";
      this.saveForm = {};
    },

    makeupPw() {
      const textLine =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*+?";
      let str = "";
      for (let i = 0; i < 15; i++) {
        str += textLine.charAt(Math.floor(Math.random() * textLine.length));
      }

      this.password = str;
    },

    // 아이디 중복 체크  => api 에서 전달된 status 값에 따라 true, false 반환
    // true => 중복된 데이터
    // false => 중복안된 데이터
    async duplicationCheck(id) {
      try {
        const result = await findOwnerUuid(id);
        if (result.status !== 200) throw "중복된 정보 입니다.";
        return false;
      } catch (e) {
        return true;
      }
    },

    async createOwnerUser() {
      try {
        const result = await createOwnerUser(this.saveForm);

        if (result.status !== 200) throw "저장 실패";

        await this.$emit("callGetCompanys");

        this.snackbarOn("저장에 성공 했습니다");
        this.dialogClose();
      } catch (e) {
        this.snackbarOn("저장에 실패 했습니다");
      }
    },

    async saveOwner() {
      const emailDomain = `${this.uuid}@${this.domain}`;
      const emailRegEx =
        /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
      const telRegEx =
        /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

      if (!this.uuid || !this.domain) {
        this.snackbarOn("이메일을 입력해 주세요");
        return;
      }

      if (!emailRegEx.test(emailDomain)) {
        this.snackbarOn("올바른 양식의 이메일을 입력해주세요");
        return;
      }

      if (await this.duplicationCheck(emailDomain)) {
        this.snackbarOn("이미등록된 계정정보 입니다.");
        return;
      }

      if (!this.password) {
        this.snackbarOn("비밀번호 를 입력해주세요");
        return;
      }

      if (!this.name) {
        this.snackbarOn("신청자명을 입력해 주세요");
        return;
      }

      if (!this.tel) {
        this.snackbarOn("전화번호를 입력해 주세요");

        return;
      }

      if (!telRegEx.test(this.tel)) {
        this.snackbarOn("올바른 양식의 전화번호를 입력해주세요");
        return;
      }

      if (confirm("기초데이터 설정 신청을 하시겠습니까?")) {
        this.saveForm = {
          company: this.company.id,
          name: this.name,
          password: this.password,
          uuid: emailDomain,
          tel: this.tel.replace(/-/g, ""),
        };

        await this.createOwnerUser();
      }
    },
  },

  created() {
    this.initForm();
  },
};
</script>
