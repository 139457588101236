<template>
  <v-dialog
    max-width="600px"
    :value="isAdminDialog"
    @click:outside="close"
    @keydown.esc="close"
    @keydown.enter="callSave"
  >
    <v-card>
      <v-card-title>
        <span>
          {{
            `${payData.pay_id} 결제 ${
              payData.saveType === "cancel" ? "취소" : "승인"
            }`
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <TextField disabled :value="payData.company" label="업체명" />
            </v-col>

            <v-col cols="3">
              <TextField disabled :value="payData.depositor" label="입금자명" />
            </v-col>
            <v-col cols="4">
              <TextField disabled :value="payData.pay_at" label="입금날짜" />
            </v-col>
            <v-col cols="12">
              <TextField v-model="memo" label="메모" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> 아니오 </v-btn>
        <v-btn color="blue darken-1" text @click="callSave"> 예 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TextField from "@/components/atoms/VTextField/TextField.vue";
import { mapGetters } from "vuex";
import { adminPaySave } from "@/api/pay";
export default {
  data() {
    return {
      memo: "",
    };
  },
  components: {
    TextField,
  },
  computed: {
    ...mapGetters({
      isAdminDialog: "getAdminConfirmDialog",
      payData: "getPayData",
    }),
  },
  methods: {
    close() {
      this.$store.commit("setAdminConfirmDialog");
    },
    payDataParse() {
      const payData = this.payData;
      payData.memo = this.memo;
      payData.approved_by = this.$store.state.auth_id;
    },
    async callSave() {
      try {
        this.payDataParse();

        this.$store.dispatch("SET_API_LOADING", true);

        const result = await adminPaySave(this.payData);

        this.$store.dispatch("SET_API_LOADING", false);
        this.$store.commit("setAdminConfirmDialog");

        if (result.status !== 200) throw `error: ${result.status}`;
        await this.$store.dispatch("CALL_ADMIN_PAYMENTLIST", {
          company: this.$store.state.company._id,
          sort: "desc",
          status: "all",
          type: "request",
        });
      } catch (e) {
        alert("저장에 실패하였습니다. 다시시도해주세요.");
      }
    },
  },
};
</script>
