<template>
  <div>
    <div class="d-flex submenuArea">
      <div class="d-flex align-center infoArea compnayLists">
        총 &nbsp;<span class="blue--text">{{ companys.length }}</span>
        개 업체
      </div>
      <div
        class="d-flex align-center"
        style="margin-left: auto; display: flex; gap: 20px"
      >
        <v-btn
          class="mx-1"
          fab
          dark
          color="indigo"
          small
          @click="clickNewCompanyDialog()"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>

    <div class="compnaysGrid">
      <div
        v-for="head in compnaysHeader"
        :key="head.text"
        class="compnaysGridHeader"
      >
        <div>
          <span>{{ head.text }}</span>
        </div>
      </div>
    </div>
    <overlay-scrollbars
      :options="{
        overflowBehavior: {
          x: 'hidden',
        },
      }"
      ref="scrollBar"
      defer
    >
      <div
        style="
          max-height: calc(100vh - 224px);
          border-bottom: thin solid #0000001f;
        "
      >
        <div
          class="compnaysGrid compnaysGridGridBody"
          v-for="item in companys"
          :key="item._id"
        >
          <div
            v-for="head in compnaysHeader"
            :key="head.value"
            class="bodyItem"
          >
            <div v-if="head.value === 'ownerUser'">
              <span v-if="item.ownerUser">{{ item.ownerUser }}</span>
              <div
                v-else
                class="settingBtn"
                @click="clickOwnerCreateDialog(item)"
              >
                계정 생성
              </div>
            </div>
            <span v-else>{{ item[head.value] ? item[head.value] : "" }}</span>
          </div>
        </div>
      </div>
    </overlay-scrollbars>

    <SnackBar v-model="snackbar" :timeout="timeout">
      {{ snackbar_text }}
    </SnackBar>

    <OwnerUserInputDialog
      :isDialog="isUserDialog"
      :company="userCompany"
      @close="clickOwnerCreateDialog"
      @snackbarOn="snackbarOn"
      @callGetCompanys="callGetCompanys"
    />

    <CompanyInputDialog
      :isDialog="isCompanyDialog"
      @snackbarOn="snackbarOn"
      @close="clickNewCompanyDialog"
      @callGetCompanys="callGetCompanys"
    />
  </div>
</template>

<script>
import { getCompanys } from "../../../../api/backOffice";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import OwnerUserInputDialog from "@/components/pages/backOffice/company/OwnerUserInputDialog.vue";
import CompanyInputDialog from "@/components/pages/backOffice/company/CompanyInputDialog.vue";

export default {
  components: {
    OwnerUserInputDialog,
    CompanyInputDialog,
    SnackBar,
  },
  computed: {},

  data() {
    return {
      companys: [],
      compnaysHeader: [
        {
          text: "업체명",
          value: "name",
        },
        {
          text: "관리자명",
          value: "officer",
        },
        {
          text: "id",
          value: "id",
        },
        {
          text: "사업자 번호",
          value: "business_num",
        },
        {
          text: "전화번호",
          value: "tel",
        },
        {
          text: "생성일",
          value: "regDate",
        },
        {
          text: "관리자 계정",
          value: "ownerUser",
        },
      ],
      isCompanyDialog: false,

      // 관리자 계정 생성에 필요한 데이터
      isUserDialog: false,
      userCompany: {},

      snackbar: false,
      snackbar_text: "",
      timeout: 1500,
    };
  },

  methods: {
    // snackbarOn 실행
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },

    clickOwnerCreateDialog(compnay) {
      this.isUserDialog = !this.isUserDialog;
      this.userCompany = compnay ? compnay : {};
    },

    clickNewCompanyDialog() {
      this.isCompanyDialog = !this.isCompanyDialog;
    },

    // 등록된 업체의 관리자 계정을 조회 하는 API 호출
    async callGetCompanys() {
      try {
        const result = await getCompanys();

        if (result.status !== 200) throw `error : ${result.status}`;

        this.companys = result.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  created() {
    this.callGetCompanys();
  },
};
</script>
