import paymentStatus from "@/assets/data/status/paymentStatus";

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      pageList: "getAdminPaymentList",
    }),
    pageCompanyList() {
      const companyList = [
        ...this.pageList.map((data) => {
          return { label: data.companyId, value: data.companyDocId };
        }),
      ];

      companyList.unshift({
        label: "전체",
        value: "all",
      });
      return companyList;
    },
    statusList() {
      return this.tabs === 0
        ? [
            {
              text: "전체",
              value: "all",
            },
            {
              text: "결제 요청",
              value: "request",
            },
            {
              text: "취소 요청",
              value: "cancelRequest",
            },
          ]
        : [
            {
              text: "전체",
              value: "all",
            },

            {
              text: "결제 완료",
              value: "confirm",
            },
            {
              text: "결제 취소",
              value: "cancel",
            },
          ];
    },
  },
  data() {
    return {
      tabs: 0,
      company: null,
      header: [
        {
          text: "업체명",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "company",
        },
        {
          text: "주문번호",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "pay_id",
          filterable: false,
        },
        {
          text: "입금자명",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "depositor",
        },
        {
          text: "결제타입",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "item",
        },
        {
          text: "사용기간",
          width: "5%",
          value: "useDate",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "결제요청시간",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "pay_at",
        },
        {
          text: "결제완료시간",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "confirm_date",
        },
        {
          text: "결제금액",
          width: "5%",
          value: "price",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "상태",
          width: "5%",
          value: "status",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "",
          width: "5%",
          value: "button",
          align: "center",
          class: "tableHeaderBody",
        },
      ],

      sortList: [
        {
          text: "오름차순 정렬",
          value: "asc",
        },
        {
          text: "내림차순 정렬",
          value: "desc",
        },
      ],
      status: "all",
      sort: "desc",
      originalList: [],
    };
  },
  methods: {
    parseStatus(item) {
      return paymentStatus[item];
    },
    changeFilter() {
      const listType = {
        0: "request",
        1: "complete",
      };
      this.$store.dispatch("CALL_ADMIN_PAYMENTLIST", {
        company: this.company,
        sort: this.sort,
        status: this.status,
        type: listType[this.tabs],
      });
    },

    async callConfirmDialog(item, saveType) {
      item.saveType = saveType;
      this.$store.commit("setPayData", item);
      this.$store.commit("setAdminConfirmDialog");
    },
    tabMove(item) {
      const listType = {
        0: "request",
        1: "complete",
      };

      this.$store.dispatch("CALL_ADMIN_PAYMENTLIST", {
        company: this.company,
        sort: this.sort,
        status: this.status,
        type: listType[item],
      });
    },
  },

  created() {
    this.$store.dispatch("CALL_ADMIN_PAYMENTLIST", {
      company: this.company,
      sort: this.sort,
      status: this.status,
      type: "request",
    });
  },
};
